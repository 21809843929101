<template>
  <v-row v-if="editedItem">
    <v-col
      cols="12"
      sm="12"
      md="12"
    >
      <css-editor
        v-if="id"
        :onSave="handleChangeStyle"
        :config="{ id: id, template: 'event' }"
      />
      <small v-else style="color: red;">[GUARDADO REQUERIDO]: para personalizar la CSS primero debe estar guardado.</small>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
    >
      <v-checkbox
        v-model="editedItem.SelectableActivities"
        label="Actividades seleccionables"
        hide-details
        outlined
        @change="handleChange"
      />
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
    >
      <v-checkbox
        v-model="editedItem.Show"
        label="Vista de show"
        hide-details
        outlined
        @change="handleChange"
      />
    </v-col>
  </v-row>
</template>
<script>
import CssEditor from './cssEditor/Index'
export default {
  components: {
    CssEditor,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      requried: true,
    },
    eventConfig: {
      type: Object,
      default: null,
    },
    onChange: {
      type: Function,
      required: true,
    },
    locale: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    editedItem: null,
  }),
  watch: {
    eventConfig () {
      this.prepareData()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      this.editedItem = this.eventConfig ? this.eventConfig : {}
    },
    handleChangeStyle (v) {
      console.log('v', v)
    },
    handleChange () {
      this.onChange(this.editedItem)
    },
  },
}
</script>
