<template>
  <v-card style="padding: 0 10px;" v-if="workspaceID">
    <v-row>
      <v-col
        cols="12"
        sm="6"
        md="6"
      >
        <h3>Share content</h3>
      </v-col>
      <v-col
        cols="12"
        sm="6"
        md="6"
        style="text-align:right;"
      >
        <v-btn
          color="primary"
          dark
          @click="handleEdit"
          small
        >
          Configurar
        </v-btn>
        <share-form 
          :config="config"
          :onClose="handleClose"
          :onSave="handleSave"
        />
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="12"
      >
        {{ getWorkspaceName(activeWorkspaces) }}
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
import api from '@/services/api'
import ShareForm from './Form'
export default {
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
  },
  components: {
    ShareForm,
  },
  data: () => ({
    config: null,
    allWorkspaces: null,
    activeWorkspaces: null,
  }),
  watch: {
    workspaceID () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    getWorkspaceName (v) {
      return !v ? '' : v.map(item => {
        return this.allWorkspaces.filter(x => x.ID === item).map(i => i.Name).shift()
      }).join(', ')
    },
    handleGetData () {
      api.getAllWithoutLimit ('dviewcontent', `v1/system/workspaces/${this.workspaceID}/share-content-config`)
        .then(response => {             
          this.allWorkspaces = response.allWorkspaces.filter(x => x.ID !== this.workspaceID)
          this.activeWorkspaces = response.activeWorkspaces
        })
    },
    handleClose () {
      this.config = null
    },
    handleSave (v) {
      api.updateItem ('dviewcontent', `v1/system/workspaces/`, `${this.workspaceID}/share-content-config`, v)
        .then(() => {             
          this.activeWorkspaces = v
          this.handleClose()
        })
    },
    handleEdit () {
      this.config = {
        allWorkspaces: this.allWorkspaces,
        activeWorkspaces: this.activeWorkspaces,
      }
    },
  },
}
</script>

