<template>
  <v-dialog
    v-model="showForm"
    max-width="1000px"
  >
    <v-card v-if="editedItem">
      <v-card-title>
        <span class="headline">{{ formTitle }}</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.Name"
                :label="$t('sysUser.name', locale)"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="6"
            >
              <v-select
                v-model="editedItem.Type"
                :items="types"
                :label="$t('workspace.contentType.type', locale)"
                :disabled="editedItem.ID !== null"
                item-text="name"
                item-value="value"
                outlined
                hide-details
              />
            </v-col>
          </v-row>
          <v-row
            v-if="isWithSize"
          >
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.Width"
                :label="$t('workspace.contentType.width', locale)"
                hide-details
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
              md="6"
            >
              <v-text-field
                v-bind="{ 'outlined' : true}"
                v-model="editedItem.Height"
                :label="$t('workspace.contentType.height', locale)"
                hide-details
              />
            </v-col>
          </v-row>
          <v-row
            v-if="isContainsImage"
          >
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-select
                v-model="editedItem.AllowedTypes"
                :items="allowedTypes"
                :label="$t('workspace.contentType.allowedTypes', locale)"
                multiple
                item-text="name"
                item-value="value"
                chips
              />
            </v-col>
          </v-row>
          <v-row
            v-if="editedItem.Type === 'audio'"
          >
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-switch
                v-model="editedItem.BySequence"
                :label="$t('workspace.contentType.bySequence', locale)"
                color="primary"
                hide-details
              />
            </v-col>
          </v-row>
          <event-config
            v-if="editedItem.Type === 'event'"
            :id="editedItem.ID"
            :eventConfig="editedItem.EventConfig"
            :onChange="handleUpdateEventConfig"
            :locale="locale"
            :workspaceID="workspaceID"
          />
          <weekly-menu-config
            v-if="editedItem.Type === 'weeklyMenu'"
            :editedItem="editedItem"
            :onChange="handleUpdateWeeklyMenuConfig"
            :locale="locale"
            :workspaceID="workspaceID"
          />
          <meeting-room-config
            v-if="editedItem.Type === 'meetingRoom'"
            :editedItem="editedItem"
            :onChange="handleUpdateMeetingRoomConfig"
            :locale="locale"
            :workspaceID="workspaceID"
          />
          <activity-barcelo-config
            v-if="editedItem.Type === 'activityBarcelo'"
            :editedID="editedItem.ID"
            :onChange="handleUpdateActivityBarceloConfig"
            :locale="locale"
            :workspaceID="workspaceID"
          />
          <!--v-row
            v-if="editedItem.Type === 'weeklyMenu'"
          >
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-btn
                color="primary"
                @click="handleAddWeeklyMenuItem"
              >
                {{ $t('common.add', locale) }}
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
              :key="editedItem.WeeklyMenuConfig"
            >
              {{editedItem}}
            </v-col>
          </v-row-->
        </v-container>
      </v-card-text>
      <div style="padding: 0 25px 10px 25px;">
      <action-buttons
        :id="`${editedItem.ID}${editedItem.tmpNewItemID ? editedItem.tmpNewItemID : ''}`"
        :onDeleteItem="onDelete"
        :onSaveItem="handleSave"
        :onClose="handleClose"
        :locale="locale"
      />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from 'vuex'
  import constants from '@/constants'
  import api from '@/services/api'
  //import utils from '@/services/utils'
  import ActionButtons from '@/components/ActionButtons'
  import WeeklyMenuConfig from './weeklyMenuConfig/Index'
  import MeetingRoomConfig from './meetingRoomConfig/Index'
  import ActivityBarceloConfig from './activityBarceloConfig/Index'
  import EventConfig from './eventConfig/Index'
  export default {
    name: 'ContentTypeForm',
    components: {
      ActionButtons,
      WeeklyMenuConfig,
      MeetingRoomConfig,
      ActivityBarceloConfig,
      EventConfig,
    },
    props: {
      workspaceID: {
        type: String,
        required: true,
      },
      data: {
        type: Object,
        default: null
      },
      showForm: {
        type: Boolean,
        default: false
      },
      onChangeContentType: {
        type: Function,
        required: true
      },
      onClose: {
        type: Function,
        required: true
      },
      onDelete: {
        type: Function,
        required: true
      },
    },
    data: () => ({
      allowedTypes: [],
      formTitle: '',
      editedItem: null,
      isWithSize: false,
      isContainsImage: false,
    }),
    computed: {
      ...mapState('app',['locale']),
      types () {
        return constants.dviewcontentTypes
      },
      /*
      allowedTypes () {
        return constants.dviewcontentAllowedTypes
      },*/
    },
    watch: {
      'editedItem.Type' (v) {
        const aux = constants.dviewcontentTypes.filter(x => x.value===v)
        this.isWithSize = aux.length > 0 && aux[0].withSize === true
        this.isContainsImage = aux.length > 0 && aux[0].containsImage === true
      },
      data (v) {
        this.editedItem = v
      },
    },
    mounted () {
      this.handleGetAllowedTypes()
    },
    methods: {
      handleGetAllowedTypes () {
        if (!this.workspaceID) return
        api.getAllWithoutLimit ('dviewcontent', `v1/system/workspaces/${this.workspaceID}/custom-content-types`)
          .then(response => {
            const aux = JSON.parse(JSON.stringify(constants.dviewcontentAllowedTypes)).map(item => {
              return {
                name: item,
                value: item
              }
            })
            aux.push(...response.map(item => {
              return {
                value: item.ID,
                name: item.Name,
              }
            }))
            
            this.allowedTypes = aux
          })
          .catch(error => {
            alert(error)
          })
      },
      handleSave() {
        this.onChangeContentType(this.editedItem)
      },
      handleClose () {
        this.onClose()
      },
      handleUpdateWeeklyMenuConfig (v) {
        this.editedItem.WeeklyMenuConfig = v
      },
      handleUpdateMeetingRoomConfig (v) {
        this.editedItem.MeetingRoomConfig = v
      },
      handleUpdateActivityBarceloConfig (v) {
        this.editedItem.ActivityBarceloConfig = v
      },
      handleUpdateEventConfig (v) {
        console.log('event', v)
        this.editedItem.EventConfig = v
      },
    },
  }
</script>
