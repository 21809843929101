<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="290"
    >
      <v-card v-if="config">
        <v-card-title class="text-h5">
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="editedItem"
            :items="config.allWorkspaces"
            outlined
            chips
            small-chips
            multiple
            itemText="Name"
            itemValue="ID"
            hide-details
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="onClose"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="primary"
            @click="handleSave"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    config: {
      type: Object,
      default: null,
    },
    onSave: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    editedItem: null,
  }),
  watch: {
    config () {
      this.editedItem = this.config ? JSON.parse(JSON.stringify(this.config.activeWorkspaces)) : []
      this.dialog = this.config ? true : false
    },
  },
  methods: {
    handleSave () {
      this.onSave(this.editedItem)
    },
  },
}
</script>
